import React, { CSSProperties } from "react";

export function IconTwitter({ style }: { style?: CSSProperties }): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M32 6.078C30.81 6.6 29.542 6.946 28.22 7.114C29.58 6.302 30.618 5.026 31.106 3.488C29.838 4.244 28.438 4.778 26.946 5.076C25.742 3.794 24.026 3 22.154 3C18.522 3 15.598 5.948 15.598 9.562C15.598 10.082 15.642 10.582 15.75 11.058C10.296 10.792 5.47 8.178 2.228 4.196C1.662 5.178 1.33 6.302 1.33 7.512C1.33 9.784 2.5 11.798 4.244 12.964C3.19 12.944 2.156 12.638 1.28 12.156C1.28 12.176 1.28 12.202 1.28 12.228C1.28 15.416 3.554 18.064 6.536 18.674C6.002 18.82 5.42 18.89 4.816 18.89C4.396 18.89 3.972 18.866 3.574 18.778C4.424 21.376 6.836 23.286 9.704 23.348C7.472 25.094 4.638 26.146 1.57 26.146C1.032 26.146 0.516 26.122 0 26.056C2.906 27.93 6.35 29 10.064 29C22.136 29 28.736 19 28.736 10.332C28.736 10.042 28.726 9.762 28.712 9.484C30.014 8.56 31.108 7.406 32 6.078Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function IconGitHub({ style }: { style?: CSSProperties }): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M15.998 0C7.164 0 0 7.192 0 16.064C0 23.16 4.584 29.18 10.944 31.306C11.744 31.454 12.036 30.958 12.036 30.532C12.036 30.15 12.022 29.14 12.014 27.8C7.564 28.77 6.624 25.646 6.624 25.646C5.898 23.79 4.848 23.296 4.848 23.296C3.394 22.3 4.956 22.32 4.956 22.32C6.562 22.434 7.406 23.976 7.406 23.976C8.834 26.43 11.152 25.722 12.064 25.31C12.208 24.272 12.622 23.564 13.08 23.162C9.528 22.756 5.792 21.378 5.792 15.224C5.792 13.47 6.416 12.036 7.44 10.912C7.274 10.506 6.726 8.872 7.596 6.662C7.596 6.662 8.94 6.23 11.996 8.308C13.272 7.952 14.64 7.774 16.002 7.768C17.36 7.776 18.73 7.952 20.008 8.31C23.062 6.232 24.404 6.664 24.404 6.664C25.276 8.876 24.728 10.508 24.564 10.914C25.59 12.038 26.208 13.472 26.208 15.226C26.208 21.396 22.468 22.754 18.904 23.152C19.478 23.648 19.99 24.628 19.99 26.126C19.99 28.274 19.97 30.006 19.97 30.532C19.97 30.962 20.258 31.462 21.07 31.304C27.42 29.176 32 23.158 32 16.064C32 7.192 24.836 0 15.998 0Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function IconEmail({ style }: { style?: CSSProperties }): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M14.4314 15.8356C15.267 16.3927 16.7331 16.3927 17.5689 15.8355C17.569 15.8355 17.5692 15.8353 17.5693 15.8353L31.811 6.34075C31.3438 4.98974 30.0598 4.01648 28.5521 4.01648H3.44793C1.9401 4.01648 0.656156 4.98974 0.188965 6.34075L14.431 15.8353C14.4311 15.8355 14.4313 15.8355 14.4314 15.8356Z"
        fill="currentColor"
      />
      <path
        d="M18.6093 17.3956C18.6091 17.3957 18.609 17.3958 18.6088 17.3959C17.8775 17.8835 16.9386 18.1273 16 18.1273C15.0612 18.1273 14.1227 17.8835 13.3913 17.3958C13.3912 17.3958 13.3911 17.3957 13.391 17.3956L0 8.46838V24.5355C0 26.4366 1.5467 27.9832 3.4479 27.9832H28.5522C30.4534 27.9832 32 26.4366 32 24.5355V8.46838L18.6093 17.3956Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function IconDiscord({ style }: { style?: CSSProperties }): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M4.77301 28.2613H23.785L22.877 25.3213L25.049 27.1853L27.0397 28.9693L30.6663 32V3.3C30.5757 1.516 29.0397 0 27.1357 0L4.77967 0.004C2.87701 0.004 1.33301 1.52267 1.33301 3.30667V24.96C1.33301 26.8413 2.87434 28.2613 4.77301 28.2613V28.2613ZM18.837 7.57733L18.793 7.59333L18.809 7.57733H18.837ZM8.66234 9.26933C11.1063 7.49067 13.3717 7.576 13.3717 7.576L13.5543 7.756C10.5637 8.46933 9.20767 9.80533 9.20767 9.80533C9.20767 9.80533 9.57034 9.628 10.2037 9.35733C14.2317 7.77333 18.6303 7.888 22.7023 9.89333C22.7023 9.89333 21.3437 8.644 18.537 7.844L18.785 7.6C19.173 7.60133 21.2263 7.67333 23.4237 9.28C23.4237 9.28 25.8823 13.48 25.8823 18.64C25.801 18.5413 24.357 20.8613 20.641 20.9413C20.641 20.9413 20.0117 20.2293 19.5637 19.608C21.737 18.984 22.5503 17.736 22.5503 17.736C21.837 18.1853 21.1863 18.452 20.6583 18.7187C19.8463 19.076 19.033 19.252 18.221 19.432C14.3757 20.056 12.217 19.012 10.177 18.184L9.47967 17.8293C9.47967 17.8293 10.2917 19.0773 12.3783 19.7013C11.8303 20.3267 11.2877 21.0373 11.2877 21.0373C7.57301 20.9493 6.21834 18.6293 6.21834 18.6293C6.21834 13.4613 8.66234 9.26933 8.66234 9.26933V9.26933Z"
        fill="currentColor"
      />
      <path
        d="M19.0774 17.028C20.0254 17.028 20.7974 16.228 20.7974 15.2413C20.7974 14.2613 20.0294 13.4613 19.0774 13.4613V13.4653C18.1334 13.4653 17.3601 14.2626 17.3574 15.2493C17.3574 16.228 18.1294 17.028 19.0774 17.028Z"
        fill="currentColor"
      />
      <path
        d="M12.9197 17.028C13.8677 17.028 14.6397 16.228 14.6397 15.2413C14.6397 14.2613 13.873 13.4613 12.925 13.4613L12.9197 13.4653C11.9717 13.4653 11.1997 14.2626 11.1997 15.2493C11.1997 16.228 11.9717 17.028 12.9197 17.028V17.028Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function IconDAI({ style }: { style?: CSSProperties }): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M22.6687 17.1298H10.6288C10.5072 17.1298 10.4472 17.1298 10.4177 17.0999C10.389 17.0707 10.389 17.0133 10.389 16.9001V15.1203C10.389 14.9799 10.4293 14.9302 10.5791 14.9302H22.6788C22.8091 14.9302 22.8689 14.9799 22.8689 15.1001C22.9186 15.7193 22.9186 16.3414 22.8689 16.9599C22.8595 17.1298 22.7983 17.1298 22.6687 17.1298Z"
        fill="currentColor"
      />
      <path
        d="M22.1899 12.7104C22.2072 12.7551 22.2072 12.8047 22.1899 12.8501H22.2382C22.218 12.9099 22.1179 12.93 22.1179 12.93H10.5784C10.3883 12.93 10.3883 12.8897 10.3883 12.7399V9.1903C10.3883 9.05998 10.4084 9.00022 10.5582 9.00022H15.928C16.4997 8.99518 17.0699 9.05494 17.628 9.18022C18.7339 9.44231 19.7628 9.96359 20.6282 10.7002C20.801 10.829 20.9551 10.981 21.0883 11.1502C21.3705 11.4302 21.6182 11.742 21.8285 12.0804C21.9682 12.2784 22.0884 12.4894 22.1899 12.7104Z"
        fill="currentColor"
      />
      <path
        d="M21.6787 19.2099C21.8486 19.1912 22.02 19.1912 22.1899 19.2099C22.2684 19.2495 22.1986 19.4022 22.1986 19.4022C21.5837 20.606 20.6088 21.5867 19.4085 22.2109H19.3387C19.1076 22.3484 18.8628 22.4629 18.6093 22.5508C17.9124 22.8164 17.1815 22.9777 16.4385 23.031C16.1987 23.0821 15.9539 23.0994 15.7091 23.0807H10.5892C10.389 23.0807 10.389 23.0404 10.389 22.8805V19.4101C10.389 19.2099 10.4394 19.2099 10.5892 19.2099H21.6787Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 7.16407 24.8374 0 16 0C7.16407 0 0 7.16407 0 16C0 24.8366 7.16407 32 16 32C24.8374 32 32 24.8374 32 16ZM8.37946 24.88L8.37658 24.8821V24.8922H15.3167C16.1023 24.903 16.8849 24.8404 17.6568 24.7021C18.4696 24.543 19.2616 24.2925 20.0169 23.9526C20.3444 23.8009 20.6586 23.6176 20.9756 23.4327C21.0559 23.3858 21.1363 23.3389 21.2172 23.2924C21.5902 23.0267 21.9473 22.7387 22.2871 22.4327C23.2174 21.5917 23.9374 20.5427 24.3874 19.3726C24.4176 19.2416 24.5458 19.1574 24.6775 19.1826H26.5676C26.718 19.1826 26.7677 19.1322 26.7677 18.9622V17.7519C26.7778 17.636 26.7778 17.5186 26.7677 17.4027C26.7677 17.3724 26.7755 17.3422 26.7833 17.312C26.7988 17.2519 26.8142 17.1919 26.7677 17.132H25.1887C24.9979 17.132 24.9979 17.1118 24.9979 16.9419C25.0527 16.3328 25.0527 15.7215 24.9979 15.1124C24.9879 14.9324 25.0282 14.9324 25.1679 14.9324H26.5481C26.7087 14.9324 26.7684 14.892 26.7684 14.7322V13.092C26.7584 12.8717 26.7584 12.8717 26.5287 12.8717H24.7387C24.5991 12.8955 24.4651 12.8011 24.4399 12.6615C24.2347 12.1251 23.9734 11.6117 23.6587 11.1314C23.3419 10.6577 22.9848 10.2127 22.5888 9.80159C22.0625 9.27598 21.4713 8.8195 20.8291 8.4415C19.8607 7.87917 18.8016 7.48533 17.6992 7.28156C17.1643 7.18292 16.6235 7.12316 16.0792 7.10156H8.57962C8.37946 7.10156 8.37946 7.14188 8.37946 7.30173V12.6917C8.37946 12.8818 8.33914 12.8818 8.18938 12.8818H6.03944C5.8796 12.8818 5.8796 12.9113 5.8796 13.0215V14.7812C5.8796 14.941 5.92928 14.941 6.04952 14.941H8.21962C8.37946 14.941 8.37946 14.9705 8.37946 15.0908V16.9707C8.37946 17.1406 8.32906 17.1406 8.19946 17.1406H5.8796V19.0407C5.8796 19.2006 5.92928 19.2006 6.04952 19.2006H8.21962C8.37946 19.2006 8.37946 19.22 8.37946 19.3503V24.88Z"
        fill="currentColor"
      />
    </svg>
  );
}
